import { useMutation } from '@apollo/client';
import UpdateSubscriptionMutation from './graphql/users/mutations/UpdateSubscriptionMutation.graphql';

const useUpdateSubscription = (productId) => {
  const [updateSubscription] = useMutation(UpdateSubscriptionMutation, {
    variables: {
      input: {
        status: 'active',
        context: {
          inventoryId: productId,
        },
      },
    },
  });

  return updateSubscription;
};

export default useUpdateSubscription;
