import React, { useEffect, useState } from 'react';
import { Link, PageProps } from 'gatsby';
import { navigate } from 'gatsby';

import useDateFormat from 'src/hooks/useDateFormat';
import useSelectedLevel from 'src/hooks/useSelectedLevel';
import { trackEvent } from 'src/services/tracker';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { Translate } from 'src/components/Translate';
import { useAuthContext } from 'src/components/AuthProvider';
import useUpdateSubscription from 'src/api/useUpdateSubscription';
import useMembership from 'src/api/useMembership';
import useActiveProduct from 'src/api/useActiveProduct';
import logTrackedEvent from 'src/helpers/logTrackedEvent';
import useRedirect from 'src/hooks/useRedirect';
import { SimpleFooter } from 'src/features/footer';

const ChangeRenewal = ({ location }: PageProps) => {
  const { product } = useActiveProduct();
  const [level] = useSelectedLevel(1);
  const [submittedForm, setSubmittedForm] = useState(false);
  const { isAuthenticated, loading: loadingUser } = useAuthContext();
  const { data: membership, ...membershipQuery } = useMembership();
  const format = useDateFormat();

  const plan = product?.mileages[level - 1];
  const totalPrice = plan?.price.amount + product?.basePrice.amount;

  const updateSubscription = useUpdateSubscription(plan?.id);

  const i18n = {
    heading: 'changePlan.heading',
    leadText: 'changePlan.leadText',
    refundTitle: 'changePlan.refundTitle',
    question: 'changePlan.question',
    answer: 'changePlan.answer',
    bannerSuccess: 'changePlan.banner.success',
    bannerFailure: 'changePlan.banner.notAllow',
    breadcrumb: 'breadcrumbs.myAccount.text',
    confirmButton: 'updateMembership.confirmButton.text',
    cancelButton: 'updateMembership.cancelButton.text',
  };

  const { state = {} } = location;
  const shouldRedirect = !state?.['nav'];

  useRedirect('/my-account/');

  useEffect(() => {
    if (isAuthenticated) membershipQuery.refetch();
  }, [isAuthenticated]);

  useEffect(() => {
    const unauthenticated = !loadingUser && !isAuthenticated;
    const hasMembership = membership?.hasMembership();

    if (unauthenticated || hasMembership === false) {
      // use === false here will exclude the undefined case
      membershipQuery.stopPolling();
      navigate('/checkout/', { replace: true });
      // Redirect member users that navigated directly
    } else if (shouldRedirect && hasMembership) {
      membershipQuery.stopPolling();
      navigate('/my-account/change-plan/');
    }
  }, [loadingUser, isAuthenticated, membership]);

  useEffect(() => {
    if (submittedForm && plan && plan.id === membership.nextInventory?.id) {
      membershipQuery.stopPolling();
      logTrackedEvent(trackEvent, {
        _tag_name: 'HDC Renewal Plan Changed',
        price: plan.price.amount + product.basePrice.amount,
        membership_level: plan.name,
        old_membership: membership.data.name,
        price_change: membership.data.price - plan.price.amount,
      });
      navigate('/my-account/', {
        state: { msg: i18n.bannerSuccess },
      });
    }
  }, [membership?.data, submittedForm]);

  const handleOnClick = () => {
    setSubmittedForm(true);
    updateSubscription()
      .then(() => {
        membershipQuery.startPolling(200);
      })
      .catch(() => {
        navigate('/my-account/', {
          state: { msg: i18n.bannerFailure },
        });
      });
  };

  return (
    <>
      {isAuthenticated && plan && membership?.hasMembership() && (
        <div className="container container_center page__update_membership">
          <Breadcrumbs originTransKey={i18n.breadcrumb} originPath={'/my-account/'} />
          <h2 className="text-display_2 benefit-header">
            <Translate resourceKey={i18n.heading} />
          </h2>
          <p className="text_2" data-cy="change-renewal-lead">
            <Translate
              resourceKey={i18n.leadText}
              values={{
                currentMembership: membership.data.name,
                expiresAt: format(membership.expireAt),
              }}
            />
          </p>
          <div className="inset-l" />

          <div className="text_2" data-cy="refund-title">
            <Translate resourceKey={i18n.refundTitle} />{' '}
            <span className="refund-due-amount" data-cy="refund-due-amount">
              ${totalPrice.toFixed(2)}
            </span>
          </div>

          <div className="inset-s" />

          <div className="text-heading_3" data-cy="change-renewal-question">
            <Translate resourceKey={i18n.question} />
          </div>
          <div className="text_3" data-cy="change-renewal-answer">
            <Translate resourceKey={i18n.answer} />
          </div>
          <div className="inset-m" />
          <div className="cta_button-group button-group">
            <div className="button-group__item">
              <button
                onClick={handleOnClick}
                data-cy="confirm-change-renewal-membership"
                className="button button_primary button_primary--large"
                disabled={submittedForm}
              >
                <Translate resourceKey={i18n.confirmButton} />
              </button>
            </div>
            <div className="button-group__item">
              <Link to={'/my-account/'} className="button button_secondary">
                <Translate resourceKey={i18n.cancelButton} />
              </Link>
            </div>
          </div>
        </div>
      )}
      <div className="inset-l" />

      <SimpleFooter />
    </>
  );
};

export default ChangeRenewal;
